// src/pages/VideoGallery.jsx
import React, { useState } from 'react';
import Slider from "react-slick"; // Importamos el carrusel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Componente de flecha personalizada
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        borderRadius: "50%",
        padding: "5px",
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        borderRadius: "50%",
        padding: "5px",
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

const VideoGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  // Importar todas las imágenes de la carpeta "Galeria"
  const importAllImages = (r) => {
    return r.keys().map(r);
  };

  const images = importAllImages(require.context('../assets/images/Galeria', false, /\.(png|jpe?g|svg)$/));

  const videos = [
    "w8NTx5NP2Eg",
    "IyPzvZG5KYU",
    "-hpvmjUtUig",
  ];

  // Configuración del carrusel de imágenes
  const imageSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Mostrar 4 imágenes a la vez
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />, // Flecha personalizada
    nextArrow: <CustomNextArrow />, // Flecha personalizada
    responsive: [
      {
        breakpoint: 1024, // Ajustar para pantallas medianas
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768, // Ajustar para pantallas pequeñas
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480, // Ajustar para pantallas muy pequeñas
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  // Configuración del carrusel de videos
  const videoSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Mostrar 2 videos a la vez
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />, // Flecha personalizada
    nextArrow: <CustomNextArrow />, // Flecha personalizada
    responsive: [
      {
        breakpoint: 768, // Ajustar para pantallas medianas
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id="video-gallery" className="py-20 text-black ">
      <div className="container mx-auto px-6">
        {/* Carrusel de imágenes */}
        <div className="mt-10">
          <Slider {...imageSettings}>
            {images.map((image, index) => (
              <div key={index} className="px-2"> {/* Ajustar espaciado */}
                <img
                  src={image}
                  alt={`Imagen ${index + 1}`}
                  className="w-full h-auto object-cover rounded-lg shadow-lg cursor-pointer"
                  onClick={() => handleImageClick(image)}
                />
              </div>
            ))}
          </Slider>
        </div>

        {/* Carrusel de videos */}
        <div className="mt-10">
          <Slider {...videoSettings}>
            {videos.map((video, index) => (
              <div key={index} className="px-2"> {/* Ajustar espaciado */}
                <iframe
                  width="100%"
                  height="400"
                  src={`https://www.youtube.com/embed/${video}`}
                  title={`Video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg shadow-lg"
                ></iframe>

              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Modal para mostrar la imagen ampliada */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <img src={selectedImage} alt="Ampliada" className="w-3/4 h-auto rounded-lg shadow-lg" />
        </div>
      )}
    </section>
  );
};

export default VideoGallery;
