import React from "react";
import { FaCalendarAlt, FaUserPlus, FaTimes } from "react-icons/fa";

const Sidebar = ({ isOpen, toggleMenu, toggleReservePopup, toggleJoinPopup }) => {
  return (
    <div
      className={`fixed top-0 right-0 h-full w-full bg-very-dark-blue bg-opacity-90 z-50 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}
    >
      <div className="flex justify-end p-4">
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          <FaTimes size={24} />
        </button>
      </div>
      <nav className="flex flex-col space-y-4 text-lg font-caviar p-4">
        <button 
          onClick={() => {
            toggleReservePopup(); // Abre el popup para "Reservar presentación"
            toggleMenu(); // Cierra el Sidebar
          }}
          className="hover:text-yellow-300 cursor-pointer flex items-center text-white"
        >
          <FaCalendarAlt className="mr-2" /> Reservar presentación
        </button>
        <button 
          onClick={() => {
            toggleJoinPopup(); // Abre el popup para "Quiero ser parte de Calle Real"
            toggleMenu(); // Cierra el Sidebar
          }}
          className="bg-blue-800 text-white py-2 px-4 rounded-lg hover:bg-blue-900 cursor-pointer flex items-center"
        >
          <FaUserPlus className="mr-2" /> Quiero ser parte de Calle Real
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
