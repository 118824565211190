import React from "react";
import { motion } from "framer-motion";
import Header from "./components/Header";
import AboutUs from "./pages/AboutUs";
import VideoGallery from "./pages/VideoGallery";
import MisionVision from "./pages/MisionVision";
import Services from "./pages/Services";
import Footer from "./components/Footer";
import backgroundImage from "./assets/Sitio y App/Fotos/Fondos2.jpg";
import frameImage from "./assets/Sitio y App/Waves/Recurso 15.png";
import Team from "./pages/Team";
import Locations from "./pages/Locations";

function App() {
  return (
    <div className="App">
      {/* Header con imagen de fondo y links de navegación */}
      <Header />

      {/* Imagen de fondo al inicio con el marco superpuesto */}
      <div className="relative w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="absolute top-0 left-0 right-0 flex flex-col justify-center items-center text-center text-white pt-48">
          <motion.h1
            className="text-4xl md:text-5xl font-poppins-extrabold text-shadow"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Agrupación Folclórica
          </motion.h1>
          <motion.h2
            className="text-9xl md:text-8xl font-montez mt-4 text-shadow"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Calle Real
          </motion.h2>
        </div>
        <img src={frameImage} alt="Marco" className="absolute bottom-0 w-full h-auto object-contain pointer-events-none" />
      </div>

      {/* Sección de "Quiénes somos" */}
      <AboutUs />

      {/* Sección de "Servicios" */}
      <MisionVision />

      {/* Sección de "Nuestro Equipo" */}
      <Services />

      {/* Sección de "Solicitar una presentacion" */}
      <Team />
    
      
      {/*Gallery */}
      <VideoGallery/>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;