import React from 'react';
import { motion } from 'framer-motion';
import teamHeaderImage from '../assets/Sitio y App/Waves/Recurso 6.png'; // Asegúrate de tener una imagen en esta ruta
import teamImage1 from '../assets/Sitio y App/Blob María y Keyron.png'; // Asegúrate de tener una imagen en esta ruta
import teamImage2 from '../assets/Sitio y App/Blob Karen.png'; // Asegúrate de tener una imagen en esta ruta

// Nuevas imágenes
import iconImage1 from '../assets/Sitio y App/íconos/Servicios/Recurso 1.png';
import iconImage2 from '../assets/Sitio y App/íconos/Servicios/Recurso 5.png';
import iconImage3 from '../assets/Sitio y App/íconos/Servicios/Recurso 12.png';

const Services = () => {
  return (
    <section id="team" className="relative bg-white text-gray-800 min-h-screen flex flex-col items-center">
      {/* Imagen de encabezado */}
      <div className="absolute top-0 left-0 w-full h-48 sm:h-64 transform rotate-180 z-10 overflow-hidden">
        <img src={teamHeaderImage} alt="Team Header" className="w-full h-full object-cover" />
      </div>

      <div className="container mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-center mt-48 sm:mt-56 md:mt-64 z-20">
        <h2 className="text-3xl sm:text-4xl font-bold text-center text-[#012CAA] font-lemonmilk">Servicios</h2>

        {/* Ajuste del párrafo para que quede bien centrado */}
        <div className="flex justify-center items-center w-full mt-4 sm:mt-6 relative">
          <p className="w-full md:w-1/2 lg:w-1/3 text-base sm:text-lg text-center font-raleway z-20">
            Ofrecemos servicios personalizados de espectáculos de danza folclórica costarricense para su evento, empresa o festividad. Construya el suyo según:
          </p>
        </div>

        {/* Imagen izquierda - mostrada arriba de los íconos en pantallas pequeñas */}
        <div className="sm:hidden mt-8 w-full flex justify-center">
          <img src={teamImage1} alt="Team Member 1" className="w-3/4 h-auto object-cover" />
        </div>

        <div className="relative w-full mt-10 sm:mt-20 flex items-center justify-center">
          {/* Sección de imágenes en el centro en orientación horizontal */}
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-8 z-10"> {/* Ajustamos el espaciado */}
            {/* Primera imagen e información */}
            <div className="text-center flex flex-col items-center max-w-xs"> {/* Limita el ancho del texto */}
              <img src={iconImage1} alt="Icon 1" className="w-16 sm:w-20 h-auto mb-2" />
              <p className="text-gray-900 text-sm font-medium">Además de una sección musical para espectáculos con música en vivo.</p> {/* Texto más oscuro y legible */}
            </div>
            {/* Segunda imagen e información */}
            <div className="text-center flex flex-col items-center max-w-xs"> {/* Limita el ancho del texto */}
              <img src={iconImage2} alt="Icon 2" className="w-16 sm:w-20 h-auto mb-2" />
              <p className="text-gray-900 text-sm font-medium">Según el tiempo que requiera, podremos presentar variedad de bloques artísticos con sus respectivos vestuarios folclóricos.</p> {/* Texto más oscuro y legible */}
            </div>
            {/* Tercera imagen e información */}
            <div className="text-center flex flex-col items-center max-w-xs"> {/* Limita el ancho del texto */}
              <img src={iconImage3} alt="Icon 3" className="w-16 sm:w-20 h-auto mb-2" />
              <p className="text-gray-900 text-sm font-medium">Contamos con un elenco de adolescentes, adultos y niños.</p> {/* Texto más oscuro y legible */}
            </div>
          </div>
        </div>

        {/* Imagen derecha - mostrada debajo de los íconos en pantallas pequeñas */}
        <div className="sm:hidden mt-8 w-full flex justify-center">
          <img src={teamImage2} alt="Team Member 2" className="w-3/4 h-auto object-cover" />
        </div>

        {/* Mantener la posición original de las imágenes en pantallas más grandes */}
        <div className="hidden sm:block absolute top-0 left-0 w-1/3" style={{ transform: 'translateY(-20%)' }}>
          <img src={teamImage1} alt="Team Member 1" className="w-full h-auto object-cover" />
        </div>

        <div className="hidden sm:block absolute bottom-0 right-0 w-1/3" style={{ transform: 'translateY(40%)' }}>
          <img src={teamImage2} alt="Team Member 2" className="w-full h-auto object-cover" />
        </div>
      </div>
    </section>
  );
};

export default Services;
