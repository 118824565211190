import React, { useState } from 'react';
import imagenHeader from '../assets/Sitio y App/Waves/Recurso 8.png';
import teamMember1 from '../assets/Sitio y App/Directora General Carmen Brenes.png';
import teamMember2 from '../assets/Sitio y App/Directora Administrativa Ivannia Valverde.png';
import teamMember3 from '../assets/Sitio y App/Director Artístico Carlos Zúñiga.png';
import teamMember4 from '../assets/Sitio y App/Sub Directora Artística Dayanna Rojas.png'; // Nueva imagen del cuarto miembro
import { motion } from 'framer-motion';

const Team = ({ text, link }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Datos del equipo con un miembro adicional
  const teamMembers = [
    {
      name: 'Carmen Brenes',
      role: 'Directora General',
      phone: '+506 8402 5247',
      image: teamMember1,
    },
    {
      name: 'Ivannia Valverde',
      role: 'Directora Administrativa',
      phone: '+506 6082 1643',
      image: teamMember2,
    },
    {
      name: 'Carlos Zúñiga',
      role: 'Director Artístico',
      phone: '+506 8624 1790',
      image: teamMember3,
    },
    {
      name: 'Dayanna Rojas',
      role: 'Sub Directora Artística',
      phone: '+506 8751 8673',
      image: teamMember4,
    },
  ];

  return (
    <section id="team" className="relative bg-[#0099FF] text-white min-h-screen flex flex-col items-center">
      {/* Imagen de encabezado */}
      <div className="w-full h-64 sm:h-80 md:h-96 transform rotate-180 -mt-12 sm:-mt-16 md:-mt-20 z-10">
        <img src={imagenHeader} alt="Header" className="absolute w-full h-full object-cover" />
      </div>

      {/* Título "Nuestro equipo" */}
      <h2 className="text-3xl sm:text-4xl font-bold mt-20">Nuestro equipo</h2>

      {/* Contenedor del equipo */}
      <div className="flex flex-wrap justify-center items-center mt-10 w-full px-4">
        {teamMembers.map((member, index) => (
          <div key={index} className="flex flex-col items-center space-y-2">
            {/* Imagen en círculo sin borde y mucho más grande */}
            <img
              src={member.image}
              alt={member.name}
              className="w-72 h-72 sm:w-96 sm:h-96 rounded-full object-contain" // Cambiado a object-contain y aumentado el tamaño
            />
            {/* Nombre, rol y teléfono */}
            <h3 className="text-2xl sm:text-3xl font-semibold">{member.name}</h3>
            <p className="text-base sm:text-lg">{member.role}</p>
            <p className="text-base sm:text-lg text-gray-200">{member.phone}</p>
          </div>
        ))}
      </div>

      {/* Texto adicional al final */}
      <div className="mt-12 px-4 sm:px-8 lg:px-16 text-center">
        <p className="text-lg sm:text-xl leading-relaxed">
          Texto sobre la cantidad de bailarines y diferentes elencos. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        </p>
      </div>
    </section>
  );
};

export default Team;
