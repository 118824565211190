import React from 'react';
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';
import footerHeaderImage from '../assets/Sitio y App/Waves/Recurso 16.png'; // Asegúrate de tener esta imagen en la ruta correcta

const Footer = () => {
  return (
    <footer className="relative text-white h-64 sm:h-80 md:h-96"> {/* Asignamos la altura del footer */}
      {/* Imagen de fondo para el footer */}
      <div className="absolute top-0 left-0 w-full h-full z-0">
        <img src={footerHeaderImage} alt="Footer Header" className="w-full h-full object-cover" />
      </div>
      
      {/* Contenido del footer alineado en la parte inferior */}
      <div className="absolute bottom-0 w-full z-10 text-center px-4 sm:px-6 lg:px-8 pb-6"> {/* Añadimos absolute y bottom-0 para colocar el contenido al final */}
        <p className="font-raleway text-sm sm:text-base">&copy; 2024 Agrupación Folclórica Calle Real. Todos los derechos reservados.</p>
        <p className="text-sm sm:text-base">La Unión, Tres Ríos, Cartago - Costa Rica</p>
        
        <div className="mt-4">
          <p className="flex items-center justify-center space-x-2"><FaPhone /> <span>Teléfono: +506 86241790</span></p>
          <p className="flex items-center justify-center space-x-2"><FaEnvelope /> <span>Correo: info@afcallereal.com</span></p>
          <p className="flex items-center justify-center space-x-2"><FaMapMarkerAlt /> <span>Ubicación: La Unión, Tres Ríos, Cartago, Costa Rica</span></p>
          
          <div className="mt-4 flex justify-center space-x-4">
            <a href="https://www.facebook.com/AFCalleReal" className="text-white hover:text-yellow-500"><FaFacebook size={24} /></a>
            <a href="https://www.instagram.com/afcallereal/" className="text-white hover:text-yellow-500"><FaInstagram size={24} /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
