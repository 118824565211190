import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import aboutImage from '../assets/Sitio y App/Blob Jimena.png'; 

const AboutUs = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); // Dejar de observar después de que sea visible
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <section ref={ref} id="about" className="py-10 md:py-20 bg-very-dark-blue text-white">
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center">
        {/* Imagen del lado izquierdo */}
        <div className="w-full md:w-1/2 h-auto mb-8 md:mb-0 md:pr-6">
          <img 
            src={aboutImage} 
            alt="About Us" 
            className="w-full h-auto object-cover rounded-lg shadow-lg" 
          />
        </div>

        {/* Información del lado derecho */}
        <div className="w-full md:w-1/2 md:pl-6">
          <motion.h2 
            className="text-3xl md:text-4xl font-bold text-white font-lemonmilk text-center md:text-left"
            initial={{ opacity: 0, y: -50 }}
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
            transition={{ duration: 1 }}
          >
            ¿Quiénes Somos?
          </motion.h2>

          <motion.p 
            className="mt-4 md:mt-6 text-base md:text-lg font-raleway text-center md:text-left"
            initial={{ opacity: 0 }}
            animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Calle Real nace el 11 de abril del 2010 en la escuela Central de Tres Ríos, Costa Rica, como parte del proyecto de Festival Estudiantil de las Artes (FEA). 
            Por iniciativa de la entonces directora del centro educativo Sra. Msc Flory Aguilar Rojas.
          </motion.p>

          <motion.p 
            className="mt-4 md:mt-6 text-base md:text-lg font-raleway text-center md:text-left"
            initial={{ opacity: 0 }}
            animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            El grupo se independizó en el 2013, y actualmente está dirigido en la parte artística por Carlos Zúñiga y bajo la dirección general de Carmen Brenes. 
            Participantes de diferentes festivales a nivel nacional e internacional, pasacalles, festival de la luz, etc. El grupo está formado por 35 bailarines, en secciones: 
            infantil, juvenil y mayor.
          </motion.p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
