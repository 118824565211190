import React, { useState } from 'react';
import axios from 'axios';
import { FaCalendarAlt, FaUserPlus, FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/Sitio y App/logo callereal.png';
import Sidebar from './Sidebar';
import headerImage from '../assets/Sitio y App/Waves/Recurso 23.png'; // Asegúrate de que la ruta de la imagen sea correcta
import joinImage from '../assets/Sitio y App/Waves/Recurso 3.png'; // Asegúrate de que la ruta de la imagen sea correcta
import backgroundImage from '../assets/images/Galeria/afcallereal Costa Rica 13.jpg'; // Asegúrate de tener una imagen de fondo adecuada

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showReservePopup, setShowReservePopup] = useState(false);
  const [showJoinPopup, setShowJoinPopup] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    eventType: '',
    eventDate: '',
    duration: '',
    additionalDetails: '',
  });

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleReservePopup = () => {
    setShowReservePopup(!showReservePopup);
    setIsSubmitted(false); // Reset submission state when reopening the popup
  };

  const toggleJoinPopup = () => {
    setShowJoinPopup(!showJoinPopup);
    setIsSubmitted(false); // Reset submission state when reopening the popup
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitPresentation = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://seahorse-app-6weak.ondigitalocean.app/api/send-presentation-email', formData);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error al enviar el correo');
    }
  };

  const handleSubmitJoin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://seahorse-app-6weak.ondigitalocean.app/api/send-join-email', formData);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error al enviar el correo');
    }
  };

  return (
    <>
      <header className="bg-gradient-to-b from-very-dark-blue to-transparent text-white fixed w-full z-50 h-[20vh]">
        <div className="container mx-auto px-6 relative h-full">
          <div className="flex justify-between items-center pt-4">
            <div className="flex items-center">
              <img src={logo} alt="Calle Real Logo" className="h-24 md:h-32 mr-3 w-auto" />
            </div>
            <div className="md:hidden">
              <button onClick={toggleMenu} className="text-white focus:outline-none">
                <FaBars size={24} />
              </button>
            </div>
            <nav className="hidden md:flex flex-row space-x-8 text-lg font-caviar items-center">
              <button
                onClick={toggleReservePopup}
                className="hover:text-yellow-300 cursor-pointer flex items-center"
              >
                <FaCalendarAlt className="mr-2" /> Reservar presentación
              </button>
              <button
                onClick={toggleJoinPopup}
                className="bg-blue-800 text-white py-2 px-4 rounded-lg hover:bg-blue-900 cursor-pointer flex items-center"
              >
                <FaUserPlus className="mr-2" /> Quiero ser parte de Calle Real
              </button>
            </nav>
          </div>
        </div>
        <Sidebar
          isOpen={isOpen}
          toggleMenu={toggleMenu}
          toggleReservePopup={toggleReservePopup}
          toggleJoinPopup={toggleJoinPopup}
        />
      </header>

      {/* Popup para Reservar presentación */}
      {showReservePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
          <div className="bg-[#cfdce6] rounded-lg p-4 w-11/12 sm:w-3/4 lg:w-1/2 max-h-[90vh] overflow-y-auto relative">
            {/* Botón para cerrar el popup */}
            <button onClick={toggleReservePopup} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 focus:outline-none">
              <FaTimes size={24} />
            </button>

            {/* Ajuste de la imagen de encabezado */}
            <div className="w-full h-32 sm:h-48 md:h-56 transform rotate-180 overflow-hidden">
              <img src={headerImage} alt="Header Decor" className="w-full h-full object-cover" />
            </div>
            
            <h2 className="text-2xl font-bold mb-4 text-center">Reservar Presentación</h2>
            <p className="text-center mb-4 text-sm text-gray-700">
              ¡Gracias por tu interés en nuestros servicios de danza folclórica! Por favor, completa el siguiente formulario y nos pondremos en contacto contigo lo antes posible.
            </p>
            {isSubmitted ? (
              <div className="text-center text-green-600">
                <p>Formulario enviado, pronto nos pondremos en contacto contigo.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmitPresentation}>
                <div className="mb-4">
                  <label className="block text-gray-700">Nombre completo</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu nombre completo"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Empresa o institución (opcional)</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Empresa o institución"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Correo electrónico</label>
                  <input
                    type="email"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu correo electrónico"
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Número de teléfono</label>
                  <input
                    type="tel"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu número de teléfono"
                    required
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Tipo de evento</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Ej: Empresarial, acto cívico, etc."
                    required
                    name="eventType"
                    value={formData.eventType}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Fecha y hora del evento</label>
                  <input
                    type="datetime-local"
                    className="w-full p-2 border rounded-lg"
                    required
                    name="eventDate"
                    value={formData.eventDate}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Duración estimada del evento</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Duración estimada"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Estimado de bailarines requeridos para la presentación (opcional)</label>
                  <input
                    type="number"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Número de bailarines"
                    name="dancers"
                    value={formData.dancers}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Especificaciones adicionales</label>
                  <textarea
                    className="w-full p-2 border rounded-lg"
                    placeholder="Requerimientos técnicos, tipo de espacio, etc."
                    rows="4"
                    name="additionalDetails"
                    value={formData.additionalDetails}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Preferencias de contacto</label>
                  <div className="flex space-x-4 mt-2">
                    <label className="inline-flex items-center">
                      <input type="radio" name="contactPreference" value="email" className="form-radio" onChange={handleChange} />
                      <span className="ml-2">Email</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" name="contactPreference" value="telefono" className="form-radio" onChange={handleChange} />
                      <span className="ml-2">Teléfono</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" name="contactPreference" value="whatsapp" className="form-radio" onChange={handleChange} />
                      <span className="ml-2">WhatsApp</span>
                    </label>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
                  >
                    Enviar
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}

      {/* Popup para Quiero ser parte de Calle Real */}
      {showJoinPopup && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',     // Hace que la imagen cubra toda el área
            backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
            backgroundPosition: 'center',  // Centra la imagen en el contenedor
          }}
        >
          <div
            className="relative bg-very-dark-blue rounded-lg p-8 w-11/12 sm:w-3/4 lg:w-1/2 max-h-[90vh] overflow-y-auto"
          >
            {/* Botón para cerrar el popup */}
            <button onClick={toggleJoinPopup} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 focus:outline-none">
              <FaTimes size={24} />
            </button>

            {/* Ajuste de la imagen de encabezado */}
            <div className="w-full h-24 sm:h-36 md:h-48 overflow-hidden transform rotate-180">
              <img src={joinImage} alt="Join Header" className="w-full h-full object-cover" />
            </div>

            <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-center text-white mt-4">¡Sé Parte de Calle Real!</h2>
            <p className="text-center mb-4 text-sm text-white">
              Si estás interesado en ser parte de nuestra agrupación, por favor completa el siguiente formulario. ¡Nos encantaría conocerte mejor!
            </p>
            {isSubmitted ? (
              <div className="text-center text-green-600">
                <p>Formulario enviado, pronto nos pondremos en contacto contigo.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmitJoin}>
                <div className="mb-4">
                  <label className="block text-white">Nombre completo</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu nombre completo"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Número de teléfono</label>
                  <input
                    type="tel"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu número de teléfono"
                    required
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Edad</label>
                  <input
                    type="number"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu edad"
                    required
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Género</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu género"
                    required
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">¿Tienes experiencia previa?</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Comparte si tienes experiencia previa"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Lugar de Residencia</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Tu lugar de residencia"
                    required
                    name="residence"
                    value={formData.residence}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">¿Cómo escuchaste de nosotros?</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Ejemplo: Redes sociales, un amigo, etc."
                    required
                    name="referral"
                    value={formData.referral}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Preguntas o comentarios adicionales</label>
                  <textarea
                    className="w-full p-2 border rounded-lg"
                    placeholder="Escribe tus comentarios o preguntas"
                    rows="4"
                    name="comments"
                    value={formData.comments}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
                  >
                    Enviar
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;

