import React from 'react';
import { motion } from 'framer-motion';
import servicesHeaderImage from '../assets/Sitio y App/Waves/Recurso 17.png'; // Asegúrate de tener una imagen en esta ruta
import servicesImage from '../assets/Sitio y App/Blob Fabri.png'; // Asegúrate de tener una imagen en esta ruta

const MisionVision = () => {
  return (
    <section id="services" className="relative bg-[#0099ff] text-gray-800 min-h-screen flex flex-col items-center">
      {/* Imagen de encabezado con rotación */}
      <div className="relative w-full h-64 sm:h-80 md:h-96 z-10 transform rotate-180 overflow-hidden">
        <img
          src={servicesHeaderImage}
          alt="Services Header"
          className="w-full h-full object-cover"
          style={{ position: 'absolute', top: 0, left: 0 }} // Asegurar que esté pegada a los bordes
        />
      </div>

      <div className="container mx-auto px-4 sm:px-6 md:px-6 flex flex-col md:flex-row items-center mt-10 sm:mt-16 md:mt-20 z-20">
        {/* Sección de Misión y Visión en el lado izquierdo */}
        <div className="w-full md:w-1/2 md:pr-6 text-white">
          <motion.h2
            className="text-3xl sm:text-4xl font-bold font-lemonmilk"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Misión
          </motion.h2>
          <motion.p
            className="mt-4 sm:mt-6 text-base sm:text-lg font-raleway"
            style={{ color: '#000949' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Ser una compañía que forme artistas integrales y con buenas prácticas para la creación de productos artísticos responsables y de calidad.
          </motion.p>

          <motion.h2
            className="text-3xl sm:text-4xl font-bold font-lemonmilk mt-10"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Visión
          </motion.h2>
          <motion.p
            className="mt-4 sm:mt-6 text-base sm:text-lg font-raleway"
            style={{ color: '#000949' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Ser referentes nacionales como compañía artística distinguida por sus buenas prácticas y calidad en sus productos artísticos para el enriquecimiento del público nacional e internacional.
          </motion.p>
        </div>

        {/* Imagen del lado derecho */}
        <div className="w-full md:w-1/2 h-auto mt-8 md:mt-0 flex justify-end">
          <img src={servicesImage} alt="Services" className="w-auto h-auto object-contain" />
        </div>
      </div>
    </section>
  );
};

export default MisionVision;
